<template>
  <div class="campaignDetail container">
    <h1>{{ campaign.name }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab title="Overview"
        ><campaign-overview :campaignoverview="campaign"
      /></b-tab>
      <b-tab title="Promoters"><campaign-promoters /></b-tab>
      <b-tab title="Insights"><campaign-insights /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { CAMPAIGN_DETAILS } from "@/graphql/brand/query";
export default {
  data() {
    return {
      id: 0,
      campaign: [],
      processing: false,
    };
  },
  components: {
    CampaignOverview: () =>
      import(
        /* webpackChunkName: "CampaignOverview" */ "@/components/brand/campaigns/CampaignOverview.vue"
      ),
    CampaignPromoters: () =>
      import(
        /* webpackChunkName: "CampaignPromoters" */ "@/components/brand/campaigns/CampaignPromoters.vue"
      ),
    CampaignInsights: () =>
      import(
        /* webpackChunkName: "CampaignInsights" */ "@/components/brand/campaigns/CampaignInsights.vue"
      ),
  },
  created() {
    this.id = parseInt(this.$route.params.id);
  },
  apollo: {
    campaign: {
      query: CAMPAIGN_DETAILS,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        };
      },
      error(e) {
        this.handleError(e);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.campaignDetail {
  h1 {
    padding-left: 10px;
    font-weight: 700;
  }
  ::v-deep {
    .nav.nav-tabs {
      margin: rem(24px) 0;
      @media screen and (max-width: 991px) {
        margin-bottom: rem(35px);
      }
    }
  }
}
</style>
